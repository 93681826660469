@import '../../mixins';

.front-banner {
  &__container {
    @include mediaTablet {
      position: relative;
      left: calc(var(--container-offset) * -1);

      width: calc(100% + (var(--container-offset) * 2));
    }
  }

  &__swiper {
    width: 100%;
    height: auto;
    min-height: rem(698);
    display: flex;
    align-items: stretch;

    border-radius: var(--radius-common);
    overflow: hidden;

    @include mediaBigDesktop {
      min-height: big(698);
    }

    @include mediaLaptop {
      min-height: rem(599);
    }

    @include mediaTablet {
      min-height: rem(371);
    }

    @include mediaMobile {
      min-height: unset;
    }

    & .swiper {
      &-wrapper {
        width: 100%;
        height: auto;
      }

      &-slide {
        position: relative;

        padding: rem(100) 0;
        width: 100%;
        height: auto;
        display: flex;

        @include mediaDesktop {
          padding: var(--section-padding) 0;
        }

        @include mediaTablet {
          padding: rem(50) 0;
        }

        @include mediaMobile {
          padding: rem(40) 0 rem(25);
        }

        & .container-secondary {
          height: auto;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: rem(50);

          @include mediaBigDesktop {
            gap: big(50);
          }

          @include mediaTablet {
            padding: 0 var(--container-offset);
            display: flex;
            flex-direction: column;
            gap: rem(40);
          }

          @include mediaMobile {
            gap: rem(35);
          }
        }
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100%;
    height: 100%;

    background-color: var(--bg-dark);

    pointer-events: none;
    user-select: none;

    & img,
    & picture {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: rem(40);

    @include mediaBigDesktop {
      gap: big(40);
    }

    @include mediaMobile {
      gap: rem(35);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: rem(40);

    @include mediaBigDesktop {
      gap: big(40);
    }

    @include mediaTablet {
      gap: rem(30);
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(25);

    @include mediaBigDesktop {
      gap: big(25);
    }

    @include mediaLaptop {
      gap: rem(20);
    }

    @include mediaTablet {
      gap: rem(15);
    }
  }

  &__title {
    color: var(--text-light-primary);
  }

  &__description {
    width: 100%;
    max-width: rem(560);
    display: block;

    color: var(--text-light-secondary);
    font: var(--font-body-M);

    @include mediaBigDesktop {
      max-width: big(560);
    }

    @include mediaLaptop {
      max-width: unset;
    }
  }

  &__button {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    @include mediaMobile {
      flex-direction: column;
    }

    & .button {
      @include mediaMobile {
        width: 100%;
      }
    }
  }

  &__menu {
    --accent-light-primary: var(--text-light-primary);
  }

  &__navigation {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    & .button {
      &_icon {
        @include mediaLaptop {
          @include mediumButtonIcon {}
        }

        @include mediaMobile {
          display: none;
        }

        &.swiper-button-disabled {
          background-color: var(--stroke-light-secondary);

          pointer-events: none;

          & .button {
            &__icon {
              fill: var(--text-disable);
            }
          }
        }
      }
    }
  }

  &__pagination {
    display: none;

    @include mediaMobile {
      width: 100%;
      height: rem(10);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: rem(10);
    }
  }

  &__bullet {
    position: relative;

    width: rem(6);
    height: rem(6);
    display: flex;

    background-color: var(--stroke-light-secondary);
    border-radius: var(--radius-infinity);

    transition: background-color var(--animation-timing) var(--cubic-bezier),
      width var(--animation-timing) var(--cubic-bezier),
      height var(--animation-timing) var(--cubic-bezier);

    cursor: pointer;

    @include hover {
      background-color: var(--accent-light-primary);
    }

    &::before {
      @include pseudo(5) {}
    }

    &_active {
      width: rem(10);
      height: rem(10);

      background-color: var(--accent-light-primary);
    }
  }
}

@import "front-form";
