.front-form {
  position: relative;
  z-index: 1;
  margin-left: auto;

  padding: rem(40);
  width: 100%;
  max-width: rem(460);
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: rem(30);

  background-color: var(--bg-white);
  border-radius: var(--radius-common);

  @include mediaBigDesktop {
    padding: big(40);
    max-width: big(460);
    gap: big(30);
  }

  @include mediaLaptop {
    padding: rem(30);
    max-width: rem(345);
    gap: rem(25);
  }

  @include mediaTablet {
    padding: rem(25);
    width: 100%;
    max-width: unset;
    gap: rem(20);
  }

  @include mediaMobile {
    padding: rem(20);
  }

  &__description {
    color: var(--text-dark-secondary);
    font: var(--font-body-XS);
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(30);

    @include mediaBigDesktop {
      gap: big(30);
    }

    @include mediaLaptop {
      gap: rem(25);
    }

    @include mediaTablet {
      gap: rem(20);
    }

    @include mediaMobile {
      gap: rem(15);
    }
  }

  &__inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }

    @include mediaTablet {
      gap: rem(10);
    }

    & .text-input {
      width: 100%;
    }
  }

  &__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    @include mediaMobile {
      gap: rem(5);
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }
  }

  &__submit {
    width: 100%;
  }

  &__privacy {
    color: var(--text-dark-secondary);
    font: var(--font-body-XS);

    & a {
      color: var(--accent-light-primary);
      text-decoration: none;

      transition: color var(--animation-timing) var(--cubic-bezier);

      @include hover {
        color: var(--accent-light-secondary);
      }
    }
  }
}
