@mixin mediumButtonIcon {
  width: rem(44);
  height: rem(44);

  @include mediaBigDesktop {
    width: big(44);
    height: big(44);
  }

  & .button {
    &__icon {
      width: rem(20);
      height: rem(20);

      @include mediaBigDesktop {
        width: big(20);
        height: big(20);
      }
    }
  }

  @content;
}

